import React from 'react'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { Content } from 'Components/Content/Content'

export type ArticleContentProps = {
  className?: string
  article: ArticleFragment
}

export const ArticleContent = (props: ArticleContentProps) => {
  const { className = '', article, ...other } = props

  if (!article) {
    return null
  }

  return <Content text={article.content} Component="article" {...other} />
}
